import classNames from 'classnames';
import { Icon } from '@/components/atoms/Icon';

import styles from './index.module.scss';

type ToggleStyleType = 'label' | 'card';

interface ShowAllToggleProps {
  readonly showAll: boolean;
  readonly toggleShowAll: () => void;
  readonly showMoreText?: string;
  readonly showLessText?: string;
  readonly className?: string;
  readonly styleType?: ToggleStyleType;
  readonly textStyle?: string;
  readonly dataIgnoreClick?: boolean;
  readonly icons?: {
    readonly arrowUp?: string;
    readonly arrowDown?: string;
  };
}

export function ShowAllToggle({
  className,
  showAll,
  showMoreText = 'Show more',
  showLessText = 'Show less',
  toggleShowAll,
  styleType = 'card',
  textStyle = styles.showAllText,
  dataIgnoreClick,
  icons,
}: ShowAllToggleProps): JSX.Element {
  const text = showAll ? showLessText : showMoreText;
  const { arrowUp = 'arrows/arrow-up', arrowDown = 'arrows/arrow-down' } = icons || {};

  return (
    <div
      className={classNames(
        ShowAllToggle.displayName,
        className,
        styles.HeaderSection,
        styles.component,
        styles[styleType]
      )}
      data-ignore-click={dataIgnoreClick}
    >
      <div
        className={styles.showAllButton}
        onClick={toggleShowAll}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            toggleShowAll();
          }
        }}
        tabIndex={0}
        role="button"
      >
        <p className={textStyle}>{text}</p>
        <Icon
          className={styles.showAllIcon}
          id={showAll ? arrowUp : arrowDown}
          legacy={false}
          width={20}
          tabIndex={0}
        />
      </div>
    </div>
  );
}
ShowAllToggle.displayName = 'ShowAllToggle';
